import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom';
import { assignRoleForProductOrder, fetchAllDataAPI, filterByParams, generateLableForShipping, removeDataFromList } from '../utils/api';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { FaRegEye, FaRegFilePdf, FaRegHandPointRight, FaTrashAlt } from 'react-icons/fa';
import { CiExport } from "react-icons/ci";
import CsvDownloader from 'react-csv-downloader';
import { Badge, Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { numberFormat } from '../components/numberFormat';
import moment from 'moment';
import { FiRefreshCcw } from 'react-icons/fi';
import { MdTrackChanges } from 'react-icons/md';
import Swal from 'sweetalert2';

const AllOrder = () => {
    const ref = useRef(null);
    const [order, setOrder] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const dispatchid = localStorage.getItem('dispatchdata');
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const recordPerPage = 50;
    const lastIndex = currentPage * recordPerPage;
    const firstIndex = lastIndex - recordPerPage;
    const records = order.slice(firstIndex, lastIndex);
    const npage = Math.ceil(order.length / recordPerPage)
    const numbers = [...Array(npage + 1).keys()].slice(1)
    const [filterstate, setFilterState] = useState([]);
    const [filterorder, setFilterOrder] = useState([]);
    const [filterdelivery, setFilterDelivery] = useState([]);
    const [filtervalues, setFilterValue] = useState();
    const [checkvalues, setCheckedValue] = useState([]);
    const [filtervalidated, setFilterValidated] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [validated, setValidated] = useState(false);
    const [rolevalues, setRoleValue] = useState();

    useEffect(() => {
        if (dispatchid) {
            getAllOrders()
            getFilterStateDeliveryOrder();
        }
        else {
            navigate("/");
        }
    }, [dispatchid])

    const getAllOrders = useCallback(() => {
        setIsloading(true);
        fetchAllDataAPI('dispatch-get-all-orders-data')
            .then((res) => {
                if (res.data.status === 1) {
                    setOrder(res.data.orders);
                } else {
                    setOrder([]);
                }
                setIsloading(false);
            })
            .catch(() => {
                setIsloading(false);
                toast.error("Error fetching orders");
            });
    }, []);

    const columns = [
        {
            id: 'order_number',
            displayName: 'Order No.'
        },
        {
            id: 'cust_name',
            displayName: 'Name'
        },
        {
            id: 'cust_phone',
            displayName: 'Mobile'
        },
        {
            id: 'subtotal_price',
            displayName: 'Total Price'
        },
        {
            id: 'created_date',
            displayName: 'Order date'
        },
        {
            id: 'created_time',
            displayName: 'Order Time'
        },
        {
            id: 'state',
            displayName: 'State'
        },
        {
            id: 'city',
            displayName: 'City'
        },
        {
            id: 'pincode',
            displayName: 'Pincode'
        },
        {
            id: 'payment_method',
            displayName: 'Payment Method'
        },
        {
            id: 'vendor_name',
            displayName: 'Plateform Name'
        },
    ];
    const exportOrder = order;
    const getFilterStateDeliveryOrder = () => {
        fetchAllDataAPI('dispatch-get-state-delivery-order-filter-data').then((res) => {
            if (res.data.status === 1) {
                setFilterState(...[res.data.state])
                setFilterOrder(...[res.data.ostatus])
                setFilterDelivery(...[res.data.dstatus])
            }
        })
    }
    const handleOrderFilter = async (event) => {
        setIsloading(true);
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            try {
                const filterdata = {
                    f_order_id: filtervalues.f_order_id,
                    f_order_mobile: filtervalues.f_order_mobile,
                    f_order_awb: filtervalues.f_order_awb,
                    f_order_state: filtervalues.f_order_state,
                    f_order_sdate: filtervalues.f_order_sdate,
                    f_order_edate: filtervalues.f_order_edate,
                    f_order_dstatus: filtervalues.f_order_dstatus,
                    f_order_ostate: filtervalues.f_order_ostate,
                }
                filterByParams('dispatch-get-after-order-filter-data', filterdata).then((res) => {
                    if (res.data.status === 1) {
                        setIsloading(false);
                        setOrder(...[res.data.orders]);
                    }
                    else {
                        setIsloading(false);
                        setOrder("");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        setFilterValidated(true);
    };
    const resetOrder = () => {
        getAllOrders();
        ref.current.reset();
    }
    let handleFilterInputs = e => {
        setFilterValue({
            ...filtervalues,
            [e.target.name]: e.target.value,
        });
    };
    const handleCheckbox = (e) => {
        let isSelected = e.target.checked;
        const { name, checked } = e.target;
        let value = parseInt(e.target.value);

        if (name === "allSelect") {
            let temOrder = records.map(record => { return { ...record, isChecked: checked } });
            setOrder(temOrder);
            if (records.length === checkvalues.length) {
                setCheckedValue([]);
            }
            else {
                const postid = records.map((item) => {
                    return item.crm_order_id;
                })
                setCheckedValue(postid);
            }
        }
        else {
            let temOrder = records.map(record => record.crm_order_id === name ? { ...record, isChecked: checked } : record);
            setOrder(temOrder);
            if (isSelected) {
                setCheckedValue([...checkvalues, value]);
            }
            else {
                setCheckedValue((prevData) => {
                    return prevData.filter((id) => {
                        return id != value;
                    })
                })
            }
        }
    };
    const handleActionBtn = (e) => {
        if (checkvalues.length === 0) {
            toast.warning("Kindly select the order at least one");
        }
        else {
            localStorage.setItem("checkvalues", checkvalues);
            const actionname = e.target.value;
            if (actionname === "Role") {
                setShow(true);
            }
            else if (actionname === "Courier") {
                navigate("/assign-courier")
            }
            else {
                toast.warning("Kindly select the action name");
            }
        }
    }
    const handleCancleShipping = (shipingid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to cancle this order from shipping!!",
            icon: 'danger',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, cancel this Orders it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                removeDataFromList('dispatch-cancle-this-order-from-shipping', shipingid).then((res) => {
                    if (res.data.status === 1) {
                        Swal.fire(
                            'Canceled!',
                            'Your order has been canceled.',
                            'success'
                        )
                        getAllOrders()
                    }
                })
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })
    }
    const handleGenerateLable = (shipingid) => {
        generateLableForShipping('dispatch-generate-lable-for-shipping', shipingid).then((res) => {
            if (res.data.status === "Shiprocket") {
                toast.success("Lable has been generated successfully!!");
                window.open(res.data.labelurl, "_blank");
            }
            else {
                toast.warning("Something went wrong!!");
            }
        })
    }
    const handleOtherGenerateLable = (shipingid) => {
        window.open(`generate-lable/${shipingid}`, "_blank");
    }
    const handleAssignRole = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            const data = {
                role_type: rolevalues.role_type,
                role_reason: rolevalues.role_reason,
                checkvalues: localStorage.getItem("checkvalues"),
            };
            assignRoleForProductOrder('dispatch-assign-role-for-product-order', data).then((res) => {
                if (res.data.status === "1") {
                    Swal.fire(
                        'Success',
                        'Role has been assigned!',
                        'success'
                    );
                    setShow(false);
                    getAllOrders()
                }
            })
        }
        setValidated(true);
    };
    let handleRoleInputs = e => {
        setRoleValue({
            ...rolevalues,
            [e.target.name]: e.target.value,
        });
    };
    return (
        <div className="misDashboard">
            {/* Start Role Assign Model */}
            <Modal show={show} onHide={handleClose} animation={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you want to update this order to Assign to role?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleAssignRole}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <Form.Label>Select Assign To</Form.Label>
                                <Form.Select
                                    required
                                    name="role_type"
                                    onChange={handleRoleInputs}>
                                    <option value="">Select Assign To</option>
                                    <option value="Dealer">Dealer</option>
                                    <option value="Warehouse">Warehouse</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    <FaRegHandPointRight /> Please provide a valid Role.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="validationCustom02">
                                <Form.Label>Enter The Reason</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Enter The Reason"
                                    name="role_reason"
                                    onChange={handleRoleInputs}
                                />
                                <Form.Control.Feedback type="invalid">
                                    <FaRegHandPointRight /> Please provide a valid reason.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Button className="btn-md d-flex m-auto" type="submit">Assign Role</Button>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* End Role Assign Model */}
            <Helmet>
                <title>SATKARTAR:DISPATCH:ALL ORDERS</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center">
                        <div className="d-flex">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">All ORDERS</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="customFilter">
                        <div className="card">
                            <div className="filterBody">
                                <div className="row">
                                    <div className="col-12 col-lg-12">
                                        <Form ref={ref} noValidate validated={filtervalidated} onSubmit={handleOrderFilter} method="GET">
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="3" controlId="validationCustom01">
                                                    <Form.Label>Order ID</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Search By Order ID"
                                                        name="f_order_id"
                                                        onChange={handleFilterInputs}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" controlId="validationCustom01">
                                                    <Form.Label>Mobile Number</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Search By Mobile Number"
                                                        name="f_order_mobile"
                                                        onChange={handleFilterInputs}
                                                        pattern="[6789][0-9]{9}"
                                                        maxLength={10}
                                                        minLength={10}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" controlId="validationCustom01">
                                                    <Form.Label>AWB Number</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Search By AWB Number"
                                                        name="f_order_awb"
                                                        onChange={handleFilterInputs}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" controlId="validationCustom10">
                                                    <Form.Label>Search By State</Form.Label>
                                                    <Form.Select
                                                        placeholder="Gender Name"
                                                        name="f_order_state"
                                                        onChange={handleFilterInputs}>
                                                        <option value="">Select State</option>
                                                        {filterstate?.map((filterstate, index) => (
                                                            <option key={index} value={filterstate?.state_name}>{filterstate?.state_name}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" controlId="validationCustom01">
                                                    <Form.Label>Start Date</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        placeholder="Enter the Mobile Number"
                                                        name="f_order_sdate"
                                                        onChange={handleFilterInputs}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" controlId="validationCustom01">
                                                    <Form.Label>End Date</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        placeholder="Enter the Mobile Number"
                                                        name="f_order_edate"
                                                        onChange={handleFilterInputs}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" controlId="validationCustom10">
                                                    <Form.Label>Search By Delivery Status</Form.Label>
                                                    <Form.Select
                                                        name="f_order_dstatus"
                                                        onChange={handleFilterInputs}>
                                                        <option value="">Select Delivery Status</option>
                                                        {filterdelivery?.map((filterdelivery, index) => (
                                                            <option key={index} value={filterdelivery?.dstatus_name}>{filterdelivery?.dstatus_name}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                                <Form.Group as={Col} md="3" controlId="validationCustom10">
                                                    <Form.Label>Search By Order Status</Form.Label>
                                                    <Form.Select
                                                        name="f_order_ostate"
                                                        onChange={handleFilterInputs}>
                                                        <option value="">Select Order Status</option>
                                                        {filterorder?.map((filterorder, index) => (
                                                            <option key={index} value={filterorder?.ostatus_name}>{filterorder?.ostatus_name}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-2" style={{ marginTop: "36px" }}>
                                                <Form.Group as={Col} md="3">
                                                    <Button type="submit" className="btn btn-sm btn-primary">Submit</Button>
                                                    <span style={{ marginLeft: "10px" }} onClick={resetOrder} className="btn btn-sm btn-secondary"><FiRefreshCcw /> Refresh</span>
                                                </Form.Group>
                                                <Form.Group as={Col} md="2">
                                                    <CsvDownloader filename="All Orders"
                                                        extension=".csv"
                                                        datas={exportOrder}
                                                        columns={columns}
                                                    >
                                                        <CiExport className="ofiltersvg" />
                                                        <button className="btn btn-info btn-sm btnorderfilter"> Export</button>
                                                    </CsvDownloader>
                                                </Form.Group>
                                                <Col md={2}>
                                                    <div className="d-flex">
                                                        <Form.Label style={{ marginRight: "5px" }}>Action</Form.Label>
                                                        <Form.Select
                                                            size='md'
                                                            onChange={handleActionBtn}>
                                                            <option value="">Select Action</option>
                                                            <option value="Role">Assign Role</option>
                                                            <option value="Courier">Assign Courier</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card tablecard">
                        <div className="table-responsive">
                            <Table className="table-sm" responsive striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                name="allSelect"
                                                onChange={handleCheckbox}
                                                checked={checkvalues.length === records.length || false}
                                            />Select All
                                        </th>
                                        <th>Order No.</th>
                                        <th>Shipping ID</th>
                                        <th>AWB Number</th>
                                        <th>Product Name</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Total Amount</th>
                                        <th>Adv Amount</th>
                                        <th>Balance Amount</th>
                                        <th>State</th>
                                        <th>Order Date</th>
                                        <th>Order Time</th>
                                        <th>Delivery Status</th>
                                        <th>Order Status</th>
                                        <th>Confirm TO</th>
                                        <th>Center Name</th>
                                        <th>Agent Name</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ?
                                        <>
                                            <tr>
                                                {Array.from({ length: 19 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 19 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 19 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 19 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 19 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                        </>
                                        :
                                        records ?
                                            records?.map((record, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name={record?.crm_order_id}
                                                            value={record?.crm_order_id}
                                                            checked={record?.isChecked || false}
                                                            onChange={handleCheckbox}
                                                        />
                                                        {index + 1}

                                                    </td>
                                                    <td><Badge bg="success">{record?.order_number}</Badge></td>
                                                    <td>
                                                        <Badge bg="primary">{record?.shiping_id}</Badge>
                                                        {(() => {
                                                            if (record?.shiping_id) {
                                                                return (
                                                                    <div className="shipmentbtn">
                                                                        <span className="track"><MdTrackChanges /></span>
                                                                        {(() => {
                                                                            if (record?.ocourier_name === "Shiprocket") {
                                                                                return (
                                                                                    <span className="pdf" onClick={() => handleGenerateLable(record?.shiping_id)}><FaRegFilePdf /></span>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <span className="pdf" onClick={() => handleOtherGenerateLable(record?.shiping_id)}><FaRegFilePdf /></span>
                                                                                )

                                                                            }
                                                                        })()}
                                                                        <span onClick={() => handleCancleShipping(record?.shiping_id)} className="trash"><FaTrashAlt /></span>
                                                                    </div>
                                                                )
                                                            }
                                                        })()}

                                                    </td>
                                                    <td><Badge bg="success">{record?.awb_number}</Badge></td>
                                                    <td>
                                                        {record?.order_product?.map((prpduct) => (
                                                            <p>{prpduct?.p_name} x {prpduct?.p_qty}</p>
                                                        ))}
                                                    </td>
                                                    <td>{record?.order_name}</td>
                                                    <td>{record?.order_mobile}</td>
                                                    <td><Badge bg="secondary">{numberFormat(record?.grand_total)}</Badge></td>
                                                    <td><Badge bg="success">{numberFormat(record?.order_advance_amt)}</Badge></td>
                                                    <td><Badge bg="warning">{numberFormat(record?.total_balance)}</Badge></td>
                                                    <td>{record?.order_state}</td>
                                                    <td>{moment(record?.created_date).format('Do MMM YYYY')}</td>
                                                    <td>{record?.created_time}</td>
                                                    <td>{record?.shipping_status}</td>
                                                    <td>{record?.order_confirm_status}</td>
                                                    <td>{record?.order_confirm_to}</td>
                                                    <td>{record?.center_name}</td>
                                                    <td>{record?.agent_name}</td>
                                                    <td>
                                                        <Link target="_blank" to={`/order-invoice/${record.order_number}`} className="badge badge-success"><FaRegEye style={{ fontSize: "20px" }} /></Link>
                                                    </td>
                                                </tr>
                                            ))
                                            :
                                            <tr >
                                                <td className="text-center" colSpan={20}>Data not founds......</td>
                                            </tr>
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>#</th>
                                        <th>Order No.</th>
                                        <th>Shipping ID</th>
                                        <th>AWB Number</th>
                                        <th>Product Name</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Total Amount</th>
                                        <th>Adv Amount</th>
                                        <th>Balance Amount</th>
                                        <th>State</th>
                                        <th>Order Date</th>
                                        <th>Order Time</th>
                                        <th>Delivery Status</th>
                                        <th>Order Status</th>
                                        <th>Confirm TO</th>
                                        <th>Center Name</th>
                                        <th>Agent Name</th>
                                        <th>Action</th>
                                    </tr>
                                </tfoot>
                            </Table>
                            <nav>
                                <ul className="pagination justify-content-end round-pagination">
                                    <li className="page-item">
                                        <a href="#" className="page-link" onClick={perPage}>Previous</a>
                                    </li>
                                    {
                                        numbers.map((n, i) => (
                                            <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                <a href="#" className="page-link" onClick={() => changePage(n)}>{n}</a>
                                            </li>
                                        ))
                                    }
                                    <li className="page-item">
                                        <a href="#" className="page-link" onClick={nextPage}>Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
    function perPage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changePage(id) {
        setCurrentPage(id)
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
}

export default AllOrder